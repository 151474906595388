.menu-area .menu-items{
    box-shadow: 0px 4px 9px 0px #0000001A,0px 16px 16px 0px #00000017, 0px 37px 22px 0px #0000000D, 0px 65px 26px 0px #00000003, 0px 102px 28px 0px #00000000;
    border: 1px solid #0000001A;
    border-radius: 20px;
}
.menu-items h3{
    font-family: "Inter",sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 16.94px;
    color: #897E7E;
}

.menu-items button{
    border: 1px solid #0000001A;
    box-shadow: 0px 4px 9px 0px #0000001A, 0px 16px 16px 0px #00000017, 0px 37px 22px 0px #0000000D,0px 65px 26px 0px #00000003, 0px 102px 28px 0px #00000000;
    height: 49px;
    border-radius: 20px;
    padding: 0 20px;
}
.menu-items button p{
    font-family: "Inter",sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: left;
    color: #27312C;
}
.menu-items button img{
    margin-right: 8px;
    display: block;
}



.withdraw-btn  button{
    box-shadow: 0px 4px 9px 0px #0000001A, 0px 16px 16px 0px #00000017, 0px 37px 22px 0px #0000000D, 0px 65px 26px 0px #00000003, 0px 102px 28px 0px #00000000;
    background: #fff;
    border-radius: 20px;
    border: 1px solid #0000001A;
    height: 44px;
    font-family: "Inter",sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    text-align: center;
    width: 100%;
    color: #000;
}


.withdraw-btn  button:hover{
    background: #F09C67;}




.menu-modal{
    box-shadow: 0px 4px 9px 0px #0000001A, 0px 16px 16px 0px #00000017, 0px 37px 22px 0px #0000000D, 0px 65px 26px 0px #00000003, 0px 102px 28px 0px #00000000;
    width: 320px;
    border-radius: 20px;
    border: 1px solid #0000001A;
    padding: 20px;
    background-color: #fff;
}
.logout-content img{
    width: 50px;
    height: 50px;
}

.logout-content button{
    border-top: 2px dashed #EDEDED;
    width: 100%;
    font-family: "Open Sans",sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 36px;
    text-align: center;
}
.logout-content h2{
    font-family: "Inter",sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 39.97px;
    text-align: center;
    color: #474747;

}
.logout-content p{
    font-family: "Inter",sans-serif;
    font-size: 22px;
    font-weight: 600;
    line-height: 57px;
    text-align: center;    
    color: #474747;

}
/* Hide default checkbox */
.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom checkbox */
  .custom-checkbox {
    display: inline-block;
    position: relative;
    padding-left: 24px;
    cursor: pointer;
  }
  
  .custom-checkbox .checkmark {
    position: absolute;
    top: -10px;
    left: 0;
    height: 20px;
    width: 20px;
    border: 2px solid #333;
    border-radius: 4px;
  }
  
  /* When the checkbox is checked, show the checkmark */
  .custom-checkbox input:checked  + .checkmark {
    background-color: #1849D6;
    border-color: #1849D6;
  }
  .custom-checkbox input:checked + .checkmark::after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .custom-checkbox .checkmark::after {
    content: "";
    position: absolute;
    display: none;
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  