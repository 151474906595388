.bpro-area 
.bpro-items{
    height: 80vh;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 16px;
}
.bpro-items .coming-soon{
    box-shadow: 0px 4px 9px 0px #0000001A, 0px 16px 16px 0px #00000017, 0px 37px 22px 0px #0000000D,0px 65px 26px 0px #00000003,0px 102px 28px 0px #00000000;
    height: 233px;
    padding: 16px;
    border-radius: 20px ; 
    width: 100%;
}
.bpro-items h1{
    font-family: "Inter",sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: center;
    color: #27312C;
}
