.history-area{
    overflow-y: auto;
    padding-bottom: 60px !important;
}
.history-area::-webkit-scrollbar{
    display: none;
}

.history-area .withdraw-items{
    box-shadow: 0px 4px 9px 0px #0000001A,0px 16px 16px 0px #00000017, 0px 37px 22px 0px #0000000D, 0px 65px 26px 0px #00000003, 0px 102px 28px 0px #00000000;
    border: 1px solid #0000001A;
    border-radius: 20px;
    padding: 15px;
}
.history-items{
    border: 1px solid #0000001A;
    box-shadow: 0px 4px 9px 0px #0000001A, 0px 16px 16px 0px #00000017, 0px 37px 22px 0px #0000000D,0px 65px 26px 0px #00000003, 0px 102px 28px 0px #00000000;
    background-color: #fff;
    border-radius: 20px;
    padding: 15px;

}
.history-items h2{
    font-family: "Inter",sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 14.52px;
    text-align: left;
    color: #27312C;
    padding-bottom: 8px;
}
.history-items h3 {
    font-family: "Inter",sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    color: #27312C;
    padding-bottom: 3px;
}
.history-items h3 strong{
    font-family: "Inter",sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 14.52px;
    text-align: left;
    color: #27312C;
}

.history-items button{
    border: 1px solid #0000001A;
    box-shadow: 0px 4px 9px 0px #0000001A, 0px 16px 16px 0px #00000017, 0px 37px 22px 0px #0000000D,0px 65px 26px 0px #00000003, 0px 102px 28px 0px #00000000;
    height: 49px;
    border-radius: 20px;
    padding: 0 20px;
}
.history-items  p{
    font-family: "Inter",sans-serif;
    font-size: 8px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: center;
    color: #27312C;

}
.history-items  img{
    width: 31px;
    height: 31px;
    display: block;
}
