.forget-password-area {
    .forget-password-form {
        box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.1), 0px 16px 16px 0px rgba(0, 0, 0, 0.09), 0px 37px 22px 0px rgba(0, 0, 0, 0.05), 0px 65px 26px 0px rgba(0, 0, 0, 0.01), 0px 102px 28px 0px rgba(0, 0, 0, 0);
        min-height: 170px;
        max-height: 220px;
        border-radius: 20px;
    }

    .divider {
        height: 1px;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.1);
    }

    .form-control {
        font-family: "Inter", sans-serif !important;
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        width: 100%;
        padding-left: 40px;
        padding-right: 25px;
        padding-top: 10px;
        padding-bottom: 10px;
        height: 50px;
    }

    .has-focus.sc-ion-input-md-h input.sc-ion-input-md {
        caret-color: #fff !important;
    }

    .invalid-txt p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        font-weight: 400;
        line-height: 12.1px;
        text-align: left;
        color: rgba(255, 0, 0, 1);
    }

    .password-btn {
        background: #fff;
        box-shadow: none;
        
        img {
            width: 20.17px;
            height: 17.55px;
        }
    }

    .invalid-txt {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        font-weight: 400;
        line-height: 12.1px;
        text-align: left;
        color: rgba(255, 0, 0, 1);
    }

    .error-txt p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        font-weight: 400;
        line-height: 12.1px;
        text-align: left;
        color: rgba(255, 0, 0, 1);
    }

    .forget-password-text {
        color: rgba(137, 126, 126, 1);
        font-family: "Inter", sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.52px;
        text-align: left;
    }

    .login-btn {
        min-height: 40px;
        height: 40px;
        background: rgba(63, 82, 72, 1);
        font-family: "Inter", sans-serif;
        font-size: 12px;
        font-weight: 600;
        line-height: 14.52px;
        text-align: center;
        color: #fff;
    }

    .register-btn {
        min-height: 40px;
        height: 40px;
        background: rgba(39, 49, 44, 1);
        font-family: "Inter", sans-serif;
        font-size: 12px;
        font-weight: 600;
        line-height: 14.52px;
        text-align: center;
        color: #fff;
    }

    .desc-txt {
        line-height: 20px;
    }
}
