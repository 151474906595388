

.card-container {
    
    border-radius: 12px; /* Rounded corners */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Shadow effect */
}

.home-area .top-bar h1{
    font-family: "Inter",sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    text-align: left;
    color: #27312C;
}
.home-area{
    height: 90vh;
}

.divider {
    height: 1px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
}

.form-control {
    font-family: "Inter", sans-serif !important;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 50px;
}


.invalid-txt p {
    font-family: "Inter", sans-serif !important;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    padding-left: 20px;
    display: block;
    padding-bottom: 5px;
    color: #FF0000;
}


.password-btn {
    background: #fff;
}

.error-txt p {
    font-family: "Inter", sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 12.1px;
    text-align: left;
    color: rgba(255, 0, 0, 1);
}

.forget-password-text {
    color: rgba(137, 126, 126, 1);
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
}

.activated-btn{
    height: 40px;
    background: rgba(63, 82, 72, 1);
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 600 !important;
    text-align: center;
    color: #4b5563 !important;
    border: 1px solid #0000001A;
    box-shadow: 0px 4px 9px 0px #0000001A,0px 16px 16px 0px #00000017,0px 37px 22px 0px #0000000D, 0px 65px 26px 0px #00000003, 0px 102px 28px 0px #00000000;
    background-color: #FFFFFF;
    width: 100%;
    border-radius: 20px;
}

.user-instructions{
    box-shadow: 0px 4px 9px 0px #0000001A,0px 16px 16px 0px #00000017,0px 37px 22px 0px #0000000D,0px 65px 26px 0px #00000003,0px 102px 28px 0px #00000000;
    width: 100%;
    height: auto;
    border-radius: 20px;
    border: 1px solid #0000001A;
    padding: 15px;
}
.user-title{
    font-family: "Inter",sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;
    text-align: left;
    padding-bottom: 8px;
}