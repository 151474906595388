button img{
    width: 15px;
    height: 17px;
}
button p{
    font-family: "Inter",sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 12.1px;
    text-align: center;
    color: #CCCCCC;
}