

.deposit-area .deposit-items{
    box-shadow: 0px 4px 9px 0px #0000001A,0px 16px 16px 0px #00000017, 0px 37px 22px 0px #0000000D, 0px 65px 26px 0px #00000003, 0px 102px 28px 0px #00000000;
    border: 1px solid #0000001A;
    border-radius: 20px;
    padding: 15px;
}
.deposit-items h1{
    font-family: "Inter",sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    text-align: left;
    color: #27312C;
    padding: 5px 0;
}

.deposit-items button{
    border: 1px solid #0000001A;
    box-shadow: 0px 4px 9px 0px #0000001A, 0px 16px 16px 0px #00000017, 0px 37px 22px 0px #0000000D,0px 65px 26px 0px #00000003, 0px 102px 28px 0px #00000000;
    height: 49px;
    border-radius: 20px;
    padding: 0 20px;
}
.deposit-items button p{
    font-family: "Inter",sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: left;
    color: #27312C;
}
.deposit-items button img{
    margin-right: 8px;
    display: block;
}

.deposit-amount{
    box-shadow: 0px 4px 9px 0px #0000001A, 0px 16px 16px 0px #00000017, 0px 37px 22px 0px #0000000D, 0px 65px 26px 0px #00000003, 0px 102px 28px 0px #00000000;
    border: 1px solid #0000001A;
    border-radius: 20px;
    padding: 26px 15px;
}
.amount-box{
    box-shadow: 0px 4px 9px 0px #0000001A, 0px 16px 16px 0px #00000017, 0px 37px 22px 0px #0000000D, 0px 65px 26px 0px #00000003,0px 102px 28px 0px #00000000;
    border: 1px solid #0000001A;
    background-color: #fff;
    border-radius: 20px ;
    overflow: hidden;
    padding: 0 20px;
}
.amount-box label{
    font-family: "Inter",sans-serif;
    font-size: 13px !important;
    font-weight: 500 !important;
    line-height: 14.52px !important;
    text-align: left;
    color: #27312C;
}
.amount-box input{
    text-align: right;
    font-family: "Inter",sans-serif;
    font-size: 13px !important;
    font-weight: 500 !important;
    line-height: 14.52px !important;
    text-align: right;
    color: #27312C66;
}

.account-details{
    box-shadow: 0px 4px 9px 0px #0000001A, 0px 16px 16px 0px #00000017, 0px 37px 22px 0px #0000000D, 0px 65px 26px 0px #00000003,0px 102px 28px 0px #00000000;
    border: 1px solid #0000001A;
    background-color: #fff;
    border-radius: 20px ;
    overflow: hidden;
    padding: 16px 20px;
}
.account-details h1{
    font-family: "Inter",sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 14.52px;
    text-align: left;
    color: #27312C;
    padding-bottom: 15px;
}
.account-details h3{
    font-family: "Inter",sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    margin-bottom: 5px;
}
.copy-button img{
    width: 25px;
    height: 25px;
    padding-bottom: 3px;
}
.copy-button p{
    font-family: "Inter",sans-serif;
    font-size: 8px;
    font-weight: 400;
    line-height: 9.68px;
    text-align: left;
    color: #27312C;
}

.payment-proof{
    box-shadow: 0px 4px 9px 0px #0000001A, 0px 16px 16px 0px #00000017, 0px 37px 22px 0px #0000000D, 0px 65px 26px 0px #00000003,0px 102px 28px 0px #00000000;
    border: 1px solid #0000001A;
    background-color: #fff;
    border-radius: 20px ;
    overflow: hidden;
    padding: 10px 20px;
    height: 50px;

}
.payment-proof label{
    font-family: "Inter",sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;
    color: #27312C;
}

.deposit-file-upload{
    border: 1px solid #0000001A;
    width: 130px;
    height: 31px;
    border-radius: 10px ;
    display: flex;
    align-items: center;
    justify-content:space-between;
    padding: 0 10px;
}
.confirm-deposit button{
    box-shadow: 0px 4px 9px 0px #0000001A, 0px 16px 16px 0px #00000017, 0px 37px 22px 0px #0000000D, 0px 65px 26px 0px #00000003, 0px 102px 28px 0px #00000000;
    background: #F09C67;
    border-radius: 20px;
    border: 1px solid #0000001A;
    height: 44px;
    font-family: "Inter",sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    text-align: center;
    width: 100%;
    color: #000;

}

.confirm-deposit button:hover {
    background: #e88954; /* Slightly darker shade on hover */
   
}


.modal{
    box-shadow: 0px 4px 9px 0px #0000001A, 0px 16px 16px 0px #00000017, 0px 37px 22px 0px #0000000D, 0px 65px 26px 0px #00000003, 0px 102px 28px 0px #00000000;
    width: 320px;
    height: 231px;
    border-radius: 20px;
    border: 1px solid #0000001A;
    padding: 20px;
    background-color: #fff;
}
.payment-success img{
    width: 50px;
    height: 50px;
}

.payment-success button{
    border-top: 2px dashed #EDEDED;
    width: 100%;
    font-family: "Open Sans",sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 36px;
    text-align: center;
}
.payment-success h2{
    font-family: "Inter",sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 39.97px;
    text-align: center;
    color: #474747;

}
.payment-success p{
    font-family: "Inter",sans-serif;
    font-size: 22px;
    font-weight: 600;
    line-height: 57px;
    text-align: center;    
    color: #474747;

}
/* Hide default checkbox */
.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom checkbox */
  .custom-checkbox {
    display: inline-block;
    position: relative;
    padding-left: 24px;
    cursor: pointer;
  }
  
  .custom-checkbox .checkmark {
    position: absolute;
    top: -10px;
    left: 0;
    height: 20px;
    width: 20px;
    border: 2px solid #333;
    border-radius: 4px;
  }
  
  /* When the checkbox is checked, show the checkmark */
  .custom-checkbox input:checked  + .checkmark {
    background-color: #1849D6;
    border-color: #1849D6;
  }
  .custom-checkbox input:checked + .checkmark::after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .custom-checkbox .checkmark::after {
    content: "";
    position: absolute;
    display: none;
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  